<template>
  <v-app-bar id="app-bar" app absolute flat :color="$config.color" class="ml-3 pl-0" height="50px">
    <!-- <div class="hidden-sm-and-down font-weight-bold mr-12 white--text text-h2">
      <router-link to="/" class="v-breadcrumbs__item white--text">INFUNI</router-link>
    </div>  -->
    <v-btn
      class="ml-n5"
      color="white"
      text
      fab
      small
      @click="$vuetify.breakpoint.smAndDown ? setDrawer(!drawer) : $emit('input', !value)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>
      <v-icon v-else>
        mdi-menu
      </v-icon>
    </v-btn>
    <v-toolbar-title class="white--text font-weight-light" v-text="$t($route.meta.title)" />
    <v-spacer />
    <v-btn class="" color="white" text fab small @click="hasHistory() ? $router.go(-1) : $router.push('/')">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations } from "vuex"
export default {
  name: "DashboardCoreAppBar",
  components: {
    // DashboardCoreSettings: () => import("./Settings")
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ search: "" }),
  computed: {
    ...mapState(["drawer"]),
    profile() {
      if (this.userName) {
        if (this.userId) {
          return [
            { title: this.userName, icon: "mdi-account", action: "account" },
            { divider: true },
            { title: this.$t("Căutări salvate"), icon: "mdi-magnify-plus", action: "searchs" },
            { title: this.$t("Favorite"), icon: "mdi-heart", action: "favorites" },
            { title: this.$t("Circulație"), icon: "mdi-swap-horizontal", action: "flow" },
            { title: this.$t("Setări cont"), icon: "mdi-cog", action: "settings" },
            { divider: true },
            { title: this.$t("Ieșire cont"), icon: "mdi-logout", action: "logout" }
          ]
        } else {
          return [
            { title: this.userName, icon: "mdi-account", action: "account" },
            { divider: true },
            { title: this.$t("Căutări salvate"), icon: "mdi-magnify-plus", action: "searchs" },
            { title: this.$t("Favorite"), icon: "mdi-heart", action: "favorites" },
            { title: this.$t("Setări cont"), icon: "mdi-cog", action: "settings" },
            { divider: true },
            { title: this.$t("Ieșire cont"), icon: "mdi-logout", action: "logout" }
          ]
        }
      }
      return [
        { title: this.$t("Autentificare"), icon: "mdi-account-circle", action: "login" },
        { divider: true },
        { title: this.$t("Înregistrare"), icon: "mdi-account-plus", action: "register" }
      ]
    },
    userName() {
      return this.$store.getters.userName
    },
    userId() {
      return this.$store.getters.userId
    }
  },
  watch: {
    $route(to) {
      //this.$log("route to ", to)
      if (to.name == "CautareGenerala") {
        this.setSearch()
      } else {
        this.search = ""
      }
    }
  },
  created() {
    this.setSearch()
  },
  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER"
    }),
    hasHistory() {
      return window.history.length > 2
    },
    searchGeneral() {
      this.$router.push({ path: "/cve/" + this.search })
    },
    setSearch() {
      if (this.$route.query) {
        if (this.$route.query.q) {
          this.search = this.$route.query.q
        }
      }
    },
    changeLang(e) {
      //this.$log("change lang ", e)
      this.$i18n.locale = e
      this.$store.commit("language", e)
    },
    doAction(action) {
      this.$log("action2 ", action)
      switch (action) {
        case "profile":
          this.$router.push({ path: "/profile" })
          break
        case "login":
          this.$router.push({ path: "/autentificare" })
          break
        case "register":
          this.$router.push({ path: "/inregistrare" })
          break
        case "flow":
          this.$router.push({ path: "/cont/circulatie" })
          break
        case "favorites":
          this.$router.push({ path: "/cont/favorite" })
          break
        case "settings":
          this.$router.push({ path: "/cont/setari" })
          break
        case "logout":
          this.$store.dispatch("logOutUser")
          this.$router.push({ path: "/autentificare" })
          break
      }
    }
  }
}
</script>
